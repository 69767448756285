@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  --sidebar-width: 300px;
  --pagination-border-color: #dee2e6;
  --pagination-color: #0071eb;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > iframe {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MyCard {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.034);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.034);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.034);
}

.MyCard-Heading {
  font-size: 1.2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
}

.Disable-Focus {
  box-shadow: none;
  border: none;
  background: #edf2f7;
}

.green-check {
  font-size: 1.3em;
  height: 100%;
  margin-right: 15px;
  color: #00a800;
}

.error-check {
  font-size: 1.3em;
  height: 100%;
  margin-right: 15px;
  color: #f05555;
}

.nav-link__selected {
  background-color: red;
}

.active_nav {
  background-color: green !important;
  color: red;
}

.LoadingModelDialog-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  display: flex;
  left: 0px;
  top: 0px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(14, 32, 50, 0.35);
}

.LoadingModelDialog {
  width: 24%;
  border-radius: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 50px;
}

.LoadingModelDialog .Logo {
  width: 38%;
  height: 44%;
  border-radius: 50%;
}

/* Loans Table */
.table_head th {
  font-size: 14px;
}
